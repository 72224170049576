import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";

import "./index.css";

function Carousel() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div className="style-carousel">
      <div id="myCarousel">
        {/* <img
          src="images/carousel/carousel-4.webp"
          className="carousel-img-overlay"
          alt="Main Carousel"
        /> */}

        <video
          className="background-video carousel-img-overlay"
          width={"100%"}
          autoPlay
          playsInline
          loop
          muted
        >
          <source src="images/carousel/carousel-video.mp4" type="video/mp4" />
        </video>

        <div className="container">
          {/* <a href="#business" className="text-blue-c">
            <ul
              className="position-relative"
              style={{ left: "0%", bottom: "180px", zIndex: "2" }}
            >
              <li>
              <ArrowCircleDownIcon style={{ fontSize: "60" }} />
              </li>
              
            </ul>
          </a> */}
          <div className="carousel-caption text-dark text-center p-0">
            <div className="carousel-text-overlay">
              <h2
                className="f-s-48 text-blue-c"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="400"
                data-aos-easing="linear"
                data-aos-delay="400"
              >
                <strong>ULTIMATE EXPERTS IN USER EXPERIENCE</strong>
                <br />
              </h2>
              <p
                className="fs-18 mb-0 text-dark text-mob-display-none"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="800"
                data-aos-easing="linear"
                data-aos-delay="800"
              >
                Our Expert Teams for your Dev Dreams! <br />
                Mobile App Development · Web Development · Web Design · <br />
                Dev Ops And Much more...
                <br />
              </p>
              <p
                className="fs-18 mb-0 text-dark text-display-none"
                data-aos="fade-up"
                data-aos-anchor-placement="center-bottom"
                data-aos-duration="800"
                data-aos-easing="linear"
                data-aos-delay="800"
              >
                <p className="mb-2">
                  Our Expert Teams for your <br />
                  Dev Dreams!
                </p>
                Mobile App Development <br />
                Web Development <br /> Web Design
                <br />
                Dev Ops
                <br /> And Much more...
              </p>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Carousel;

import React, { useEffect } from "react";
import Particles from "react-tsparticles";

const particlesInit = (main) => {
  // you can initialize the tsParticles instance (main) here, adding custom shapes or presets
};

const particlesLoaded = (container) => {};

function ParticleComponent() {
  return (
    <div>
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          background: {
            color: {
              value: "#FFFFFF",
            },
            opacity: 0.5,
          },
          fpsLimit: 120,

          fullScreen: {
            enable: false,
            zIndex: -2,
          },

          interactivity: {
            events: {
              onClick: {
                enable: false,
                mode: "push",
              },
              onHover: {
                enable: false,
                mode: "repulse",
              },
              resize: true,
            },
            modes: {
              bubble: {
                distance: 10,
                duration: 10,
                opacity: 0.8,
                size: 3,
              },
              push: {
                quantity: 4,
              },
              repulse: {
                distance: 300,
                duration: 0.4,
              },
            },
          },

          particles: {
            color: {
              value: "#0075d6",
            },
            links: {
              color: "#dce8eb",
              distance: 120,
              enable: true,
              opacity: 0.5,
              width: 1,
            },
            collisions: {
              enable: true,
            },
            move: {
              direction: "none",
              enable: true,
              outMode: "bounce",
              random: false,
              speed: 0.5,
              straight: false,
            },
            number: {
              density: {
                enable: true,
                value_area: 800,
              },
              value: 80,
            },
            opacity: {
              value: 0.5,
            },
            shape: {
              type: "circle",
            },
            size: {
              random: true,
              value: 5,
            },
          },

          detectRetina: true,
        }}
      />
    </div>
  );
}

export default ParticleComponent;

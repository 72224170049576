import React, { useState } from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as Ionicons from "react-icons/io";

import { Link } from "react-router-dom";
import { SidebarData } from "./SidebarData";
import { IconContext } from "react-icons";

import "./Navbar.css";

function Navbar() {
  const [sidebar, setSidebar] = useState(false);
  const [displayNone, setDisplayNone] = useState(false);

  // console.log("setStatePaddingTop");

  const showSidebar = () => setSidebar(!sidebar);

  return (
    <>
      <IconContext.Provider value={{}}>
        <div className="navbar">
          <Link to="#" className="menu-bars">
            {displayNone == false ? (
              <FaIcons.FaBars
                style={{ color: "#0075d6" }}
                onClick={() => {
                  showSidebar();
                  setDisplayNone(true);
                }}
              />
            ) : null}
          </Link>
        </div>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <ul
            className="nav-menu-items m-0 p-0"
            onClick={() => {
              showSidebar();
              setDisplayNone(false);
            }}
          >
            <li className="navbar-toggle">
              <Link to="#" className="close-bars px-1">
                <Ionicons.IoMdClose size={50} style={{ color: "#f5f5f5" }} />
              </Link>
            </li>

            {SidebarData.map((item, index) => {
              return (
                <li key={index} className={item.cName}>
                  <a href={item.path}>
                    {item.icon}

                    <span>{item.title}</span>
                  </a>
                </li>
              );
            })}
          </ul>
        </nav>
      </IconContext.Provider>
    </>
  );
}

export default Navbar;

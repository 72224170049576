import { Hidden } from "@material-ui/core";
import { display } from "@mui/system";
import React, { useState, useEffect } from "react";

import MobileHeader from "./MobileHeader";

import "./index.css";

function Header() {
  const [navbar, setNavbar] = useState(false);
  const [navbarLogo, setNavbarLogo] = useState(
    "logo/skylinx-technologies-white.png"
  );

  const [changeNavLink, setChangeNavLink] = useState();

  const [button, setButton] = useState(false);
  const [sidebar, setSidebar] = useState(false);

  useEffect(() => {
    function changeNavColor() {
      if (window.scrollY >= 0) {
        setChangeNavLink("home");
      }
      if (window.scrollY >= 950) {
        setChangeNavLink("process");
      }
      if (window.scrollY >= 2450) {
        setChangeNavLink("projects");
      }
      if (window.scrollY >= 3890) {
        setChangeNavLink("pricing");
      }
      if (window.scrollY >= 4340) {
        setChangeNavLink("reviews");
      }
      if (window.scrollY >= 5040) {
        setChangeNavLink("contactus");
      }
    }

    window.addEventListener("scroll", changeNavColor);
    return () => window.addEventListener("scroll", changeNavColor);
  }, []);

  const changeBackground = () => {
    if (window.scrollY >= 20) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };

  useEffect(() => {
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);
  });

  //logo scroll function
  const changeLogo = () => {
    if (window.scrollY >= 20) {
      setNavbarLogo("/logo/skylinx-technologies.png");
    } else {
      setNavbarLogo("logo/skylinx-technologies.png");
    }
  };

  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeLogo);
  });

  const changeButton = () => {
    if (window.scrollY >= 20) {
      setButton(true);
    } else {
      setButton(false);
    }
  };

  useEffect(() => {
    changeLogo();
    // adding the event when scroll change Logo
    window.addEventListener("scroll", changeButton);
  });

  console.log("Scoll", window.scrollY);

  return (
    <div>
      <nav
        className={
          navbar
            ? "navbar active position-fixed navbar-expand-lg navbar-light row-100 border-none nav-smooth-style"
            : "navbar position-fixed navbar-expand-lg text-primary navbar-dark row-100 nav-smooth-style"
        }
      >
        <div className="container" id="navbar">
          <h1 title="skylinx technologies">
            <a className="navbar-brand" href="/">
              <img
                src={navbarLogo}
                width={180}
                height={90}
                alt="Skylinx Tech"
              />
            </a>
          </h1>

          <div id="nav-btn-mob">
            <MobileHeader onClick={Hidden} />
          </div>

          <div className="collapse navbar-collapse" id="navbarText">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0"></ul>
            <span className="navbar-text">
              <ul className="navbar-nav">
                <li
                  className={
                    changeNavLink === "home"
                      ? "nav-item nav-pills"
                      : "nav-item nav-pills"
                  }
                >
                  <a
                    className="nav-link mx-2"
                    href="/"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    HOME
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      changeNavLink === "process"
                        ? "nav-link active-nac-css mx-2"
                        : "nav-link mx-2"
                    }
                    href="#process"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    PROCESS
                  </a>
                </li>

                <li className="nav-item ">
                  <a
                    className={
                      changeNavLink === "projects"
                        ? "nav-link active-nac-css text-light mx-2"
                        : "nav-link mx-2"
                    }
                    href="#projects"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    PROJECTS
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      changeNavLink === "pricing"
                        ? "nav-link active-nac-css mx-2"
                        : "nav-link mx-2"
                    }
                    href="#pricing"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    PRICING
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className={
                      changeNavLink === "reviews"
                        ? "nav-link active-nac-css mx-2"
                        : "nav-link mx-2"
                    }
                    href="#reviews"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    REVIEWS
                  </a>
                </li>

                <li className="nav-item text-nowrap">
                  <a
                    className={
                      button || changeNavLink === "contactus"
                        ? "button active btn bg-blue-c text-light btn-nav-style"
                        : "button btn bg-blue-c text-light btn-nav-style"
                    }
                    href="#contact-us"
                    spy="true"
                    smooth="true"
                    offset="50"
                    duration="500"
                    onClick={() => {
                      document
                        .getElementById("navbarText")
                        .classList.remove("show");
                    }}
                  >
                    CONTACT US
                  </a>
                </li>
              </ul>
            </span>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Header;

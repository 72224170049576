import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import TimezoneSelect from "react-timezone-select";
import emailjs from "emailjs-com";

import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import validator from "validator";

import "./index.css";

function ContactUs() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [website, setWebsite] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [selectedTimezone, setSelectedTimezone] = useState({});

  const [emailError, setEmailError] = useState("");

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const validateEmail = (e) => {
    var email = e.target.value;
    // console.log(validator.isEmail(email));
    setEmail(email);
  };

  const sendEmail = (e) => {
    e.preventDefault();
    if (validator.isEmail(email)) {
      setEmailError("");
      setName("");
      setPhone("");
      setWebsite("");
      setMessage("");
      setSelectedTimezone("");
      setEmail("");

      emailjs
        .sendForm(
          "service_bywxyzl",
          "template_tu60cpf",
          e.target,
          "user_Y2Gowaly40AKOfh8HF92R"
        )
        .then(
          function (response) {
            if (response.status === 200) {
              return setOpen(true);
            }
            // console.log("SUCCESS!", response.status, response.text);
          },
          function (error) {
            if (error) {
              return setError(true);
            }
            // console.log("FAILED...", error);
          }
        );
    } else {
      setEmailError("Enter valid Email!");
    }
  };

  return (
    <div className="container font-Poppins" id="contactus">
      <h1
        className="py-5 text-blue-c"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-duration="200"
        data-aos-easing="linear"
        data-aos-delay="100"
      >
        <strong> Contact Us </strong>
      </h1>
      <div className="form-shadow">
        <div className="card mb-3 rounded-0 p-4">
          <div className="card-body">
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": { m: 1, width: "25ch" },
              }}
              noValidate
              autoComplete="off"
            ></Box>
            <form onSubmit={sendEmail}>
              <div className="row row-cols-1 row-cols-lg-2 pt-2">
                <div className="col">
                  <div className="card border-0">
                    <div className="card-body ">
                      <div className="col p-r-5">
                        <TextField
                          fullWidth
                          sx={{ m: 1 }}
                          className="pb-3 font-Poppins"
                          id="standard-textarea"
                          label="Name"
                          placeholder=""
                          value={name}
                          multiline
                          onChange={(e) => setName(e.target.value)}
                          name="name"
                          required
                          isInvalid
                          variant="standard"
                        />
                      </div>

                      <div className="col p-r-5">
                        <TextField
                          fullWidth
                          sx={{ m: 1 }}
                          className="pb-3"
                          id="phone"
                          label="Phone"
                          placeholder=""
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          name="phone"
                          type="number"
                          required
                          isInvalid
                          multiline
                          variant="standard"
                        />
                      </div>

                      <div className="col p-r-5">
                        <TextField
                          fullWidth
                          sx={{ m: 1 }}
                          className="pb-3"
                          id="website"
                          label="Website"
                          placeholder=""
                          value={website}
                          onChange={(e) => setWebsite(e.target.value)}
                          name="website"
                          type="website"
                          multiline
                          variant="standard"
                        />
                      </div>

                      <div className="col p-r-5">
                        <TextField
                          fullWidth
                          sx={{ m: 1 }}
                          className="pb-3"
                          id="email"
                          label="Email"
                          placeholder=""
                          value={email}
                          onChange={(e) => validateEmail(e)}
                          name="email"
                          type="email"
                          required
                          isInvalid
                          multiline
                          variant="standard"
                        />
                        <span
                          style={{
                            // fontWeight: "bold",
                            color: "red",
                          }}
                        >
                          {emailError}
                        </span>
                      </div>

                      <div className="col p-l-6">
                        <TimezoneSelect
                          sx={{ m: 1 }}
                          className="pt-3"
                          value={selectedTimezone}
                          onChange={setSelectedTimezone}
                          name="timezone"
                          required
                          isInvalid
                        />
                      </div>

                      <br />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="card border-0">
                    <div className="card-body p-l-5 nonee">
                      <div className="input-group">
                        <textarea
                          className="form-control "
                          id="message"
                          // style={{ height: "398px" }}
                          rows="15"
                          aria-label="With textarea"
                          placeholder="Type your Message"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          name="message"
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row p-l-5 py-m">
                <div className="col py-2">
                  <button
                    type="submit"
                    className="d-flex btn bg-blue-c text-light px-4"
                    data-bs-toggle="modal"
                    // href="#exampleModalToggle"
                    // role="button"
                  >
                    SUBMIT
                  </button>
                </div>
              </div>
              <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Your form submitted successfully!
                </Alert>
              </Snackbar>
              <Snackbar
                error={error}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert
                  onClose={handleClose}
                  severity="success"
                  sx={{ width: "100%" }}
                >
                  Please submit again!
                </Alert>
              </Snackbar>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;

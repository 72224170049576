import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import "./index.css";

function Service() {
  const [scrollBottom, setScrollBottom] = useState();

  useEffect(() => {
    function changeBottom() {
      if (window.scrollY > 0) {
        setScrollBottom("projects");
      } else {
        setScrollBottom();
      }
    }

    window.addEventListener("scroll", changeBottom);
    return () => window.addEventListener("scroll", changeBottom);
  }, []);
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div className="text-start process-section" id="process_1">
      <h1
        className="p-5 text-center text-blue-c"
        data-aos="fade-up"
        data-aos-anchor-placement="center-bottom"
        data-aos-duration="200"
        data-aos-easing="linear"
        data-aos-delay="100"
      >
        <strong> Process </strong>
      </h1>

      <div className="row bg-bluec p-0 m-0">
        <div className="col">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
              <div className="row">
                <div
                  className="card text-al-mob bg-none border-0"
                  data-aos="fade-right"
                  data-aos-duration="300"
                  data-aos-easing="linear"
                  data-aos-delay="150"
                >
                  <img
                    src="images/services/carousel/service-carousel-4.png"
                    className="card-img-top"
                    alt="img"
                    style={{ height: "218px", width: "280px" }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-9 align-self-center">
                  <div className="card bg-none border-0">
                    <div
                      className="card-body text-end"
                      data-aos="fade-left"
                      data-aos-duration="200"
                      data-aos-easing="linear"
                      data-aos-delay="100"
                    >
                      <h2 className="card-title text-hover-service">
                        <strong> Technology Stack </strong>
                      </h2>
                      <p className="card-text">
                        We are your ultimate TechStack partners who can
                        successively integrate any customizations in your
                        developments just to make them best suited for your
                        business needs! Skylinx Technologies can put life in to
                        your ideas. The smart software technologies we use are
                        Laravel, NodeJS, PHP, Ecommerce and CMS.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <p
                    className="digital-style"
                    data-aos="fade-left"
                    data-aos-duration="200"
                    data-aos-easing="linear"
                    data-aos-delay="100"
                  >
                    <strong>1</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row p-0 m-0">
        <div className="col">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
              <div className="row">
                <div className="col-3 text-start">
                  <p
                    className="digital-style pt-5"
                    data-aos="fade-right"
                    data-aos-duration="200"
                    data-aos-easing="linear"
                    data-aos-delay="100"
                  >
                    <strong>2</strong>
                  </p>
                </div>
                <div className="col-9 align-self-center">
                  <div className="card bg-none border-0">
                    <div
                      className="card-body pt-5"
                      data-aos="fade-right"
                      data-aos-duration="200"
                      data-aos-easing="linear"
                      data-aos-delay="100"
                    >
                      <h2 className="card-title">
                        <strong> Design UI/UX </strong>
                      </h2>
                      <p className="card-text">
                        Your product’s progress is directly proportional to how
                        user friendly it is. We make sure that we design the
                        UI/UX of your product in such a way that it is easily
                        accessible to the targeted audience. Our aim is to make
                        a successful digital product for you.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <div
                  className="card text-al-mob bg-none align-items-center border-0"
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-easing="linear"
                  data-aos-delay="150"
                >
                  <img
                    src="images/services/carousel/service-carousel-1.png"
                    className="card-img-top yle"
                    alt="img"
                    style={{ height: "218px", width: "280px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row bg-bluec p-0 m-0">
        <div className="col">
          <div className="container">
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
              <div className="col">
                <div
                  className="card text-al-mob bg-none border-0"
                  data-aos="fade-right"
                  data-aos-duration="300"
                  data-aos-easing="linear"
                  data-aos-delay="150"
                >
                  <img
                    src="images/services/carousel/service-carousel-2.png"
                    className="card-img-top"
                    alt="img"
                    style={{ height: "218px", width: "280px" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-9 align-self-center">
                  <div className="card bg-none border-0">
                    <div
                      className="card-body text-end pb-5"
                      data-aos="fade-left"
                      data-aos-duration="300"
                      data-aos-easing="linear"
                      data-aos-delay="150"
                    >
                      <h2 className="card-title text-hover-service">
                        <strong> Business Logics </strong>
                      </h2>
                      <p className="card-text">
                        We commit to program the communication between the data
                        base and end user in an expert manner. You will never
                        regret doing business with us and will experience the
                        perfect business logics.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-3 text-center">
                  <p
                    className="digital-style"
                    data-aos="fade-left"
                    data-aos-duration="200"
                    data-aos-easing="linear"
                    data-aos-delay="100"
                  >
                    <strong>3</strong>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-0 m-0">
        <div className="col">
          <div className="container p-25">
            <div className="row row-cols-1 row-cols-md-2 align-items-center">
              <div className="row">
                <div className="col-3 text-start">
                  <p
                    className="digital-style pt-5"
                    data-aos="fade-right"
                    data-aos-duration="300"
                    data-aos-easing="linear"
                    data-aos-delay="150"
                  >
                    <strong>4</strong>
                  </p>
                </div>
                <div className="col-9 align-self-center">
                  <div className="card bg-none border-0">
                    <div
                      className="card-body pt-5"
                      data-aos="fade-right"
                      data-aos-duration="200"
                      data-aos-easing="linear"
                      data-aos-delay="100"
                    >
                      <h2 className="card-title">
                        <strong> Testing & Delivery </strong>
                      </h2>
                      <p className="card-text">
                        But, that’s just not enough. We measure before we
                        deliver cause you know
                        <strong>
                          <i> “What’s Measured - improves”. </i>
                        </strong>
                        We offer the facility of on site testing and ensures the
                        smooth delivery with 100% customer satisfaction.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col">
                <div
                  className="card text-al-mob bg-none align-items-center border-0"
                  data-aos="fade-left"
                  data-aos-duration="300"
                  data-aos-easing="linear"
                  data-aos-delay="150"
                >
                  <img
                    src="images/services/carousel/service-carousel-3.png"
                    className="card-img-top yle"
                    alt="img"
                    style={{ height: "218px", width: "280px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="projects"
        // className={scrollBottom === "projects" ? "div_0" : "div_100"}
        className="div_0"
      ></div>
    </div>
  );
}

export default Service;

import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

import "./index.css";

function Testimonials() {
  const [scrollBottom, setScrollBottom] = useState();

  useEffect(() => {
    function changeBottom() {
      if (window.scrollY > 0) {
        setScrollBottom("contact-us");
      } else {
        setScrollBottom();
      }
    }

    window.addEventListener("scroll", changeBottom);
    return () => window.addEventListener("scroll", changeBottom);
  }, []);
  return (
    <div>
      <Carousel
        showArrows={true}
        infiniteLoop={true}
        showThumbs={false}
        showStatus={false}
        autoPlay={true}
        interval={6100}
      >
        <div>
          <img
            src="/images/testimonials/testimonial-2.webp"
            width={139}
            height={139}
            alt=""
          />
          <div className="myCarousel">
            <h3 className="pt-3">Gary Feuerstein</h3>
            <h4 className="pt-1">CEO - Prosite24</h4>
            <p>
              Skylinx Technologies has played a crucial part in the design,
              setup, and development of our platform. Communication has been
              excellent throughout the whole process and their technical
              expertise has proven invaluable.
            </p>
          </div>
        </div>

        <div>
          <img
            src="/images/testimonials/testimonial-1.webp"
            width={139}
            height={139}
            alt=""
          />
          <div className="myCarousel">
            <h3 className="pt-3">Lisa Apolinski</h3>
            <h4 className="pt-1">Founder & CEO - 3 Dog Write</h4>
            <p>
              I have had the pleasure of working with Skylinx Technologies for
              several critical projects. Faizan and his team bring solid coding
              skills as well as forward vision to these projects. This creates a
              digital asset that works in today's digital paradigm and can
              easily pivot into the next. Faizan and his team also manage these
              projects effectively and keep the projects moving to completion.
            </p>
          </div>
        </div>

        <div>
          <img
            src="/images/testimonials/testimonial-3.webp"
            width={139}
            height={139}
            alt=""
          />
          <div className="myCarousel">
            <h3 className="pt-3">Iqbal Haider</h3>
            <h4 className="pt-1">CEO - iinfinitty Media & Technologies</h4>
            <p>
              We’ve been working with Skylinx since last year and a half. We’ve
              done a number of projects together starting from Web to mobile to
              ERP to SaaS platforms. I must say Faizan and his team at Skylinx
              are very well versed when it comes to providing their clients a
              360 degrees solution. Their approach to handling any project is
              always curated for every client based on their requirement. I
              highly recommend Faizan and his team for any development and
              engineering projects. I’m and I’ll keep on hiring and engaging
              them for our future projects.
            </p>
          </div>
        </div>

        <div>
          <img
            src="/images/testimonials/testimonial-4.webp"
            width={139}
            height={139}
            alt=""
          />
          <div className="myCarousel">
            <h3 className="pt-3">Abigail Benton</h3>
            <h4 className="pt-1">Founder, The BIg Silk Ltd</h4>
            <p>
              Working with Skylinx has been an extremely cost-effective way to
              set up our online operations and having a presence in the UK was
              very reassuring. They have added a lot of value to our original
              concept thanks to their expertise in Ecommerce and their ongoing
              support is first class.
            </p>
          </div>
        </div>
      </Carousel>

     
    </div>
  );
}

export default Testimonials;

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as FcIcons from "react-icons/fc";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as IonIcons from "react-icons/io5";

export const SidebarData = [
  {
    title: "Home",
    path: "/",
    icon: <AiIcons.AiFillHome />,
    cName: "nav-text",
    value: "",
  },
  {
    title: "Process",
    path: "#process",
    icon: <AiIcons.AiFillSetting />,
    cName: "nav-text",
    value: "process",
  },
  {
    title: "Projects",
    path: "#projects",
    icon: <FaIcons.FaLaptopCode />,
    cName: "nav-text",
    value: "projects",
  },
  {
    title: "Pricing",
    path: "#pricing",
    icon: <IonIcons.IoPricetags />,
    cName: "nav-text",
    value: "pricing",
  },
  {
    title: "Reviews",
    path: "#reviews",
    icon: <AiIcons.AiFillStar />,
    cName: "nav-text",
    value: "reviews",
  },
  {
    title: "Contact Us",
    path: "#contact-us",
    icon: <IoIcons.IoMdContact />,
    cName: "nav-text",
    value: "contactus",
  },
];

import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
// import Loader from "react-loader-spinner";
import Header from "../components/Header";
import Carousel from "../components/Carousel";
import Business from "../components/Business";
import Footer from "../components/Footer";
import Service from "../components/Service";
import ContactUs from "../components/ContactUs";
import Pricing from "../components/Pricing";
import Testimonials from "../components/Testimonials";
import Projects from "../components/Projects";

import "./home.css";

function Home() {
  // const [loader, setLoader] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setLoader(true);
  //   }, 200);
  // }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div>
      {/* {loader === false ? (
        <Loader
          className="container somecss"
          type="ThreeDots"
          color="#00BFFF"
          height={80}
          width={80}
          timeout={200}
        />
      ) : ( */}

      <Header />
      <Carousel />
      <Business />
      <Service />
      <Projects />
      <Pricing />
      <div id="reviews_1">
        <h1
          className="text-center p-5 text-blue-c"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="600"
          data-aos-easing="linear"
          data-aos-delay="300"
        >
          <strong> Reviews </strong>
        </h1>
        <Testimonials />
        <div
          id="contact-us"
          // className={scrollBottom === "contact-us" ? "div_0" : "div_100"}
          className="div_0"
        ></div>
      </div>
      <ContactUs />
      <Footer />

      {/* )} */}
    </div>
  );
}

export default Home;

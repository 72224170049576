import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import "./index.css";

function Pricing({ statePaddingTop }) {
  // const [isShown, setIsShown] = useState(false);
  const [scrollBottom, setScrollBottom] = useState();

  useEffect(() => {
    function changeBottom() {
      if (window.scrollY > 0) {
        setScrollBottom("reviews");
      } else {
        setScrollBottom();
      }
    }

    window.addEventListener("scroll", changeBottom);
    return () => window.addEventListener("scroll", changeBottom);
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  // function handleMouseEnter(e) {
  //   setIsShown(true);
  // }

  function changeBackground(e) {
    e.target.style.background = "red";
  }

  function changeBackgrounds(e) {
    e.target.style.background = "none";
  }

  return (
    <div
      className="font-Poppins"
      id="pricing_1"
      data-spy="scroll"
      data-target="#navbar-example2"
      data-offset="0"
    >
      <div className="container text-start">
        <h1
          className="text-center p-5 text-blue-c"
          data-aos="fade-up"
          data-aos-anchor-placement="center-bottom"
          data-aos-duration="200"
          data-aos-easing="linear"
          data-aos-delay="100"
        >
          <strong> Pricing </strong>
        </h1>
        <div className="row row-cols-1 row-cols-lg-3 g-4">
          <div className="col mb-3 col-md-4">
            <div
              className="card border-0 price-card-shadow"
              // onMouseEnter={() => setIsShown(true)}
              // onMouseLeave={() => setIsShown(false)}
            >
              <div className="card-body none">
                <div className="row">
                  <div className="col-6 align-self-end">
                    <h6 className="card-title text-start m-0">
                      <strong>
                        Hourly Work <br />
                      </strong>
                      {/* <div>
                      <small>Starting from </small>
                      </div> */}
                    </h6>
                  </div>
                  <div className="col-6">
                    <h3 className="card-title text-end m-0">
                      <strong>{/* <sup>$</sup>20-25 */}</strong>
                    </h3>
                  </div>
                </div>
                <p className="text-end">{/* <small>Per Hour</small> */}</p>

                <hr />
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Best for small tasks</small>
                </p>

                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Free Research</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Demo available</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Limited sales support </small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Limited QA Hours</small>
                </p>
                <p className="pt-3">
                  <a href="#contact-us" className="text-dark">
                    <strong> Get started now </strong>
                    <img
                      className="px-1"
                      src="arrow.png"
                      width={32}
                      height={12}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div className="col mb-3 col-md-4">
            <div
              // className={`card border-0 ${
              //   isShown ? "" : "price-card-shadow-w"
              // }`}
              className="card border-0 price-card-shadow-w"
            >
              <div className="card-body none">
                <div className="row">
                  <div className="col-6 align-self-end">
                    <h6 className="card-title text-start text-nowrap m-0">
                      <strong>
                        Monthly Dedicated <br />
                      </strong>
                      {/* <small>Starting from </small> */}
                    </h6>
                  </div>
                  <div className="col-6">
                    <h3 className="card-title text-end m-0">
                      <strong>{/* <sup>$</sup>2200 */}</strong>
                    </h3>
                  </div>
                </div>
                <p className="text-end">{/* <small>Per Month</small> */}</p>
                <hr />
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">40 hours per week</small>
                </p>

                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2"> Dedicated Resource</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Development Manager</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">12 hours of sales support</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">QA included</small>
                </p>
                <p className="pt-3">
                  <a href="#contact-us" className="text-dark">
                    <strong> Get started now </strong>
                    <img
                      className="px-1"
                      src="arrow.png" 
                      width={32}
                      height={12}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>

          <div className="col mb-3 col-md-4">
            <div
              className="card border-0 price-card-shadow"
              // onMouseEnter={() => setIsShown(true)}
              // onMouseLeave={() => setIsShown(false)}
            >
              <div className="card-body none">
                <div className="row">
                  <div className="col-6 align-self-end">
                    <h6 className="card-title text-start m-0 text-nowrap">
                      <strong>
                        Project Based <br />
                      </strong>
                      {/* <small> As per on </small> */}
                    </h6>
                  </div>
                  <div className="col-6">
                    <h3 className="card-title text-end m-0">
                      <strong></strong>
                    </h3>
                  </div>
                </div>
                <hr />
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Documentation oriented</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Bi-weekly meetings</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Development Manager</small>
                </p>

                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">Flexible hours</small>
                </p>
                <p className="card-text ">
                  <CheckIcon className="text-blue-c" />
                  <small className="px-2">After-sales support</small>
                </p>
                <p className="pt-3">
                  <a href="#contact-us" className="text-dark">
                    <strong> Get started now </strong>
                    <img
                      className="px-1"
                      src="arrow.png"
                      width={32}
                      height={12}
                      alt=""
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="reviews"
        // className={scrollBottom === "reviews" ? "div_0" : "div_100"}
        className="div_0"
      ></div>
    </div>
  );
}

export default Pricing;

import React, { useState, useEffect } from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import AOS from "aos";
import "aos/dist/aos.css";

import "./index.css";

function Projects({ statePaddingTop }) {
  const portfolio = [
    {
      title: "",
      category: ["all", "mobile_app", "design"],
      imageSrc: "/images/portfolio/1.webp",
      img: (
        <img
          src="/images/portfolio/1.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "design"],
      imageSrc: "/images/portfolio/2.webp",
      img: (
        <img
          src="/images/portfolio/2.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "branding", "design"],
      imageSrc: "/images/portfolio/12.webp",
      img: (
        <img
          src="/images/portfolio/12.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "mobile_app", "design"],
      imageSrc: "/images/portfolio/6.webp",
      img: (
        <img
          src="/images/portfolio/6.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "branding"],
      imageSrc: "/images/portfolio/5.webp",
      img: (
        <img
          src="/images/portfolio/5.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "cms", "design"],
      imageSrc: "/images/portfolio/14.webp",
      img: (
        <img
          src="/images/portfolio/14.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "shopify", "e_commerce", "branding"],
      imageSrc: "/images/portfolio/7.webp",
      img: (
        <img
          src="/images/portfolio/7.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "e_commerce", "shopify"],
      imageSrc: "/images/portfolio/8.webp",
      img: (
        <img
          src="/images/portfolio/8.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "e_commerce", "shopify"],
      imageSrc: "/images/portfolio/9.webp",
      img: (
        <img
          src="/images/portfolio/9.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "cms"],
      imageSrc: "/images/portfolio/10.webp",
      img: (
        <img
          src="/images/portfolio/10.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "cms"],
      imageSrc: "/images/portfolio/11.webp",
      img: (
        <img
          src="/images/portfolio/11.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "mobile_app"],
      imageSrc: "/images/portfolio/4.webp",
      img: (
        <img
          src="/images/portfolio/4.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "mobile_app"],
      imageSrc: "/images/portfolio/15.webp",
      img: (
        <img
          src="/images/portfolio/15.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all"],
      imageSrc: "/images/portfolio/3.webp",
      img: (
        <img
          src="/images/portfolio/3.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
    {
      title: "",
      category: ["all", "e-commerce"],
      imageSrc: "/images/portfolio/13.webp",
      img: (
        <img
          src="/images/portfolio/13.webp"
          layout="responsive"
          width={280}
          height="auto"
          alt="projects"
        />
      ),
    },
  ];

  const [filter, setFilter] = useState("all");
  const [projects, setProjects] = useState([]);
  const [scrollBottom, setScrollBottom] = useState();

  useEffect(() => {
    function changeBottom() {
      if (window.scrollY > 0) {
        setScrollBottom("pricing");
      } else {
        setScrollBottom();
      }
    }

    window.addEventListener("scroll", changeBottom);
    return () => window.addEventListener("scroll", changeBottom);
  }, []);

  useEffect(() => {
    setProjects(portfolio);
  }, []);

  useEffect(() => {
    setProjects([]);

    const filtered = portfolio.map((p) => ({
      ...p,
      filtered: p.category.includes(filter),
    }));
    setProjects(filtered);
  }, [filter]);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  });

  return (
    <div id="projects_1">
      <div className="container font-Poppins ft-s-12">
        <div className="section-title">
          <h1
            className="p-5 text-center text-blue-c"
            data-aos="fade-up"
            data-aos-anchor-placement="center-bottom"
            data-aos-duration="100"
            data-aos-easing="linear"
            data-aos-delay="50"
          >
            <strong> Projects </strong>
          </h1>
        </div>
        <div className="site-filters style-1 clearfix center m-b40">
          <ul className="filters">
            <li className={`btn ${filter === "all" ? "active" : ""}`}>
              <a active={filter === "all"} onClick={() => setFilter("all")}>
                All
              </a>
            </li>
            <li className={`btn ${filter === "mobile_app" ? "active" : ""}`}>
              <a
                active={filter === "mobile_app"}
                onClick={() => setFilter("mobile_app")}
              >
                App
              </a>
            </li>

            <li className={`btn ${filter === "cms" ? "active" : ""}`}>
              <a active={filter === "cms"} onClick={() => setFilter("cms")}>
                CMS
              </a>
            </li>

            <li className={`btn ${filter === "design" ? "active" : ""}`}>
              <a
                active={filter === "design"}
                onClick={() => setFilter("design")}
              >
                Design
              </a>
            </li>
            <li className={`btn ${filter === "e_commerce" ? "active" : ""}`}>
              <a
                active={filter === "e_commerce"}
                onClick={() => setFilter("e_commerce")}
              >
                E-Commerce
              </a>
            </li>
          </ul>
        </div>
        <SimpleReactLightbox>
          <SRLWrapper>
            <div className="clearfix">
              <div id="masonry" className="row row-cols-1 row-cols-lg-3">
                {projects.map((item) =>
                  item.filtered === true ? (
                    <>
                      <div className="card-container col-lg-4 col-md-6 col-sm-6 g-4 web_design">
                        <div
                          data-aos="zoom-in"
                          data-aos-duration="300"
                          data-aos-delay="100"
                        >
                          <div className="dlab-box style-1 m-b30">
                            <div className="dlab-media">
                              <a>{item.img}</a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
          </SRLWrapper>
        </SimpleReactLightbox>
      </div>
      <div
        id="pricing"
        // className={scrollBottom === "pricing" ? "div_0" : "div_100"}
        className="div_0"
      ></div>
    </div>
  );
}

export default Projects;

import React, { useState, useEffect } from "react";
import "./index.css";
import ParticleComponent from "./ParticleComponent";

function Business() {
  const [particleVisible, setParticleVisible] = useState(false);
  const [scrollBottom, setScrollBottom] = useState();

  useEffect(() => {
    function changeBottom() {
      if (window.scrollY > 0) {
        setScrollBottom("process");
      } else {
        setScrollBottom();
      }
    }

    window.addEventListener("scroll", changeBottom);
    return () => window.addEventListener("scroll", changeBottom);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setParticleVisible(true);
    }, 2000);
  }, []);

  return (
    <div className="business" id="business">
      <div className="just pb-5">
        {particleVisible === true ? (
          <ParticleComponent className="particles-style" />
        ) : (
          ""
        )}

        {/* <div style={{ position: "relative", zIndex: "-2" }}>
          <div className="ripple-background">
            <div className="circle xxlarge shade1"></div>
            <div className="circle xlarge shade2"></div>
            <div className="circle large shade3"></div>
            <div className="circle mediun shade4"></div>
            <div className="circle small shade5"></div>
          </div>
        </div> */}
        <div className="container py-5 align-items-center font-Poppins text-center">
          <h4>
            We’re just the fusion of highly-skilled and experienced IT pros you
            are looking for! But, above all, we are veterans of creative
            manipulation of technology, that’s our forte!
          </h4>

          <h4 className="pt-3">
            We love to take the tech related burden. You can rely on us and
            lighten your shoulders. Just share your idea with us and we will add
            the spice and life to it! How? Well by just adding our extra
            ingredient to your idea!
          </h4>
          <h4 className="pt-3">
            Our software development services will take your product to places.
            Skylinx Technologies is committed in providing the top-notch
            software solutions and services. The right fusion of your idea and
            our tech experts, we will make your product an easy access amongst
            the masses.
          </h4>
          <br />
          <h5 className="text-dark">
            <strong>
              From companies to individuals, groups or any other business
              entities around the globe, we can tailor personalized solutions
              for anyone.
            </strong>
          </h5>
        </div>
      </div>
      <div
        id="process"
        // className={scrollBottom === "process" ? "div_0" : "div_100"}
        className="div_0"
      ></div>
    </div>
  );
}

export default Business;

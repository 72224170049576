import "./index.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailIcon from "@mui/icons-material/Mail";

function Footer() {
  return (
    <div className="style-z font-Poppins" id="footer">
      <div
        className="ft-bg-color style-ft footer-height"
        style={{ height: "450px" }}
      >
        <div className="container style-z-1">
          <div className="row row-cols-1 row-cols-lg-3">
            <div className="col">
              <div className="card bg-none border-0">
                <div className="card-body p-0">
                  <h4 className="mb-3 text-start">Follow Us</h4>
                  <div className="flex-start">
                    <ul className="list-unstyled m-0 text-center d-flex justify-content-start">
                      <a
                        className="link-light"
                        href="https://www.facebook.com/SkylinxTechnologies"
                      >
                        <li className="facebook">
                          <i className="fa fa-facebook"></i>
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://www.instagram.com/skylinx_tech"
                      >
                        <li className="ms-3 instagram">
                          <i className="fa fa-instagram"></i>
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://twitter.com/hrskylinx?lang=en"
                      >
                        <li className="ms-3 twitter">
                          <i className="fa fa-twitter"></i>
                        </li>
                      </a>

                      <a
                        className="link-light"
                        href="https://www.linkedin.com/company/skylinx-tech"
                      >
                        <li className="ms-3 linkedin">
                          <i className="fa fa-linkedin"></i>
                        </li>
                      </a>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col align-self-start">
              <div className="card bg-none border-0">
                <div className="card-body p-0 text-start">
                  <h4 className="mb-3">Our Services</h4>

                  <a
                    href="#projects"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    Mobile App Development
                  </a>
                  <a
                    href="#projects"
                    className="nav-link border-0 p-0 my-2 text-dark text-start"
                  >
                    Web Development
                  </a>

                  <a
                    href="#projects"
                    className="nav-link border-0 p-0 my-2 text-dark text-start"
                  >
                    Web Design
                  </a>
                  <a
                    href="#projects"
                    className="nav-link border-0 p-0 text-dark text-start"
                  >
                    Dev Ops
                  </a>
                </div>
              </div>
            </div>

            <div className="col align-self-center">
              <div className="card bg-none border-0">
                <div className="card-body p-0 text-start">
                  <h4 className="mb-3">Get in Touch</h4>
                  <div className="row">
                    <div className="col">
                      <a
                        href="https://g.page/SkylinxTechnologies?share"
                        className="nav-link border-0 p-0 text-dark text-start d-flex mob-style-ft-lo"
                      >
                        <LocationOnIcon className="text-blue-c" />
                        <lable className="ms-2 ml-mob-0">
                          1st Floor. 608 G1, Block G-1, Phase 1 Johar Town,
                          Lahore, Punjab
                        </lable>
                      </a>
                    </div>
                  </div>

                  <div className="row my-3">
                    <div className="col">
                      <a
                        href="mailto:info@skylinxtech.com"
                        className="nav-link border-0 p-0 text-dark text-start"
                      >
                        <MailIcon className="text-blue-c" />
                        <lable className="ms-2">info@skylinxtech.com</lable>
                      </a>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <a
                        href="tel:+924235136793"
                        className="nav-link border-0 p-0 text-dark text-start"
                      >
                        <PhoneIcon className="text-blue-c" />
                        <lable className="ms-2">(042) 35136793</lable>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="card-text text-dark text-center bg-none mt-5 mob-pb-fc">
              &copy; 2022 Skylinx Technologies. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
